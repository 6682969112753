import React from "react";
import "../styles/site-info.sass";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function SiteInfo() {
    return (
      <>
        <div className="primary-blue-bg p-4">
          <div className="site-info-container container text-white">
            <div className="row">
              <div className="d-none d-md-block col-md-3 site-info-block">
                <div className="site-info-inner-content d-flex justify-content-start p-1">
                  {/* <div className="google d-flex justify-content-center align-items-center">
                                    <i
                                        class="fab fa-google p-1"
                                        aria-hidden="true"
                                    ></i>
                                </div> */}
                </div>
                <div className="text-white text-left">
                  <a href="https://www.refrens.com/" target="_blank" rel="noopener noreferrer">
                    <img src="/refrens-invoicing.png" alt="Powered by Referens" />
                  </a>
                </div>
              </div>
              <div className="col-12 text-left text-md-center col-md-3 site-info-block">
                <h6 className="font-weight-bold pt-2 d-none d-md-block">COMPANY</h6>
                <div className="d-flex flex-column justify-content-around h-75 text-left">
                  <a href="/SML_WORKFOLIO_PRINT.pdf" className="download-brochure" download>
                    Download Brochure
                  </a>
                  <div className="text-white company-indent d-none d-md-block">
                    <HashLink to="/#faq">FAQ</HashLink>
                  </div>
                  <div className="text-white company-indent d-none d-md-block">
                    <Link to="/terms">Terms</Link>
                  </div>
                  <div className="text-white company-indent d-none d-md-block">
                    <Link to="/privacy-policy">Privacy</Link>
                  </div>
                  <div className="text-white company-indent d-none d-md-block">
                    <Link to="/blog">Blog</Link>
                  </div>
                </div>
              </div>
              <div className="d-none col-md-2 site-info-block text-left d-md-flex flex-column justify-content-around"></div>
              <div className="col-12 col-md-4 pt-4 pt-md-0 site-info-block text-left d-flex flex-column justify-content-around">
                <h6 className="font-weight-bolder primary-md-orange">Contact</h6>
                <div className="site-phone">
                  <div className="d-flex">
                    <div className="">WhatsApp:</div>
                    <div className="">
                      <a href="https://wa.me/919665194760"> &nbsp; +91 96651 94760</a>
                    </div>
                  </div>
                </div>
                <div className="site-phone">
                  <div className="d-flex">
                    <div className="">Phone:</div>
                    <div className="">
                      <a href="tel:+919665194760"> &nbsp; +91 96651 94760</a> <br />
                      <a href="tel:+918668651325"> &nbsp; +91 866 865 1325</a>
                    </div>
                  </div>
                </div>

                <div className="site-mail">
                  Email: <a href="mailto:swiftmedialabs@gmail.com">swiftmedialabs@gmail.com</a>
                </div>
                <div className="site-address">
                  Head Office: <a href="https://www.google.ca/maps/place/Whiteshield+Crescent+S,+Kamloops,+BC,+Canada/@50.6567743,-120.3395622,17z/data=!3m1!4b1!4m5!3m4!1s0x537e2c476b057887:0x8b83f66bd2908c6e!8m2!3d50.6567709!4d-120.3373735">Richmond Grand, Ahmedabad, 380051</a>
                </div>
                <div className="site-address">
                  Address: <a href="https://www.google.ca/maps/place/Boisar,+Maharashtra/@19.7977155,72.7280516,14z/data=!3m1!4b1!4m5!3m4!1s0x3be71e39499f5823:0x694aa59b0babcdca!8m2!3d19.7968929!4d72.7451817">Mumbai, India </a>
                </div>
              </div>
              <div className="col-12 d-block d-md-none pl-0">
                <div className="d-block d-md-none ">
                  <div className="d-flex justify-content-start">
                    <div className="p-2 d-flex justify-content-around follow-icons-site">
                      <a href="http://facebook.com/swiftmedialabs" className="m-1">
                        <div className="d-none">facebook</div>
                        <i class="fab fa-facebook p-1" aria-hidden="true" aria-label="facebook"></i>
                      </a>
                      <a href="http://twitter.com/swiftmedialabs" className="m-1">
                        <div className="d-none">facebook</div>
                        <i class="fab fa-twitter p-1" aria-hidden="true" aria-label="twitter"></i>
                      </a>
                      <a href="http://instagram.com/swiftmedialabs" className="m-1">
                        <div className="d-none">facebook</div>
                        <i class="fab fa-instagram p-1" aria-hidden="true" aria-label="instagram"></i>
                      </a>
                      <a href="http://linkedin.com/company/swift-media-labs" className="m-1">
                        <div className="d-none">facebook</div>
                        <i class="fab fa-linkedin-in p-1" aria-hidden="true" aria-label="linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="bottom-site-info-links d-flex d-md-none justify-content-around">
                    <div className="text-white">
                      <a href="#faq" className="text-white">
                        FAQ
                      </a>
                    </div>
                    <div>|</div>
                    <div className="text-white">
                      <a href="/terms" className="text-white">
                        Terms
                      </a>
                    </div>
                    <div>|</div>
                    <div className="text-white">
                      <a href="/privacy-policy.html" className="text-white">
                        Privacy
                      </a>
                    </div>
                    <div>|</div>
                    <div className="text-white">
                      <a href="/blog" className="text-white">
                        Blog
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-block primary-blue-bg">
          <div className="bottom-line-container d-flex justify-content-center">
            <div className="bottom-line-white d-block"></div>
          </div>
        </div>
        {/* Bottom most section */}
        <div className="container-fluid primary-blue-bg pl-md-4 m-0 bottom-site-info">
          <div className="container-fluid row">
            <div className="col-md-6 d-none d-md-block">
              <div className="d-flex justify-content-center w-50 ml-md-4">
                <div className="p-2 d-flex justify-content-around follow-icons-site">
                  <a href="http://facebook.com/swiftmedialabs" className="m-1">
                    <div className="d-none">facebook</div>
                    <i class="fab fa-facebook p-1" aria-hidden="true" aria-label="facebook"></i>
                  </a>
                  <a href="http://twitter.com/swiftmedialabs" className="m-1">
                    <div className="d-none">Twitter</div>
                    <i class="fab fa-twitter p-1" aria-hidden="true" aria-label="twitter"></i>
                  </a>
                  <a href="http://instagram.com/swiftmedialabs" className="m-1">
                    <div className="d-none">Instagram</div>
                    <i class="fab fa-instagram p-1" aria-hidden="true" aria-label="instagram"></i>
                  </a>
                  <a href="http://linkedin.com/company/swift-media-labs" className="m-1">
                    <div className="d-none">LinkedIn</div>
                    <i class="fab fa-linkedin p-1" aria-hidden="true" aria-label="linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 text-white py-4 text-md-right copyright-content">
              <div className="px-4 ml-4">
                <div className="px-4 ml-4">&copy; 2021 - All Rights Reserved</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default SiteInfo;

// const CustomDialog = ({ visible, setVisible, phoneNumber }) => {
//     return (
//         <>
//             <div className="custom-dialog">
//                 <h1>Phone Number: </h1>
//                 <div className="">Call</div>
//                 <div className="">Whatsapp</div>
//             </div>
//         </>
//     );
// };
