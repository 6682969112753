import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

import "../styles/frequently-asked-questions.sass";
export const FrequentlyAskedQuestion = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <span className="p-2 m-0 w-100 faq-slicer text-left text-white d-md-flex justify-content-md-end">
        <div className="faq-container d-flex flex-column text-left">
          <div className="d-flex justify-content-end">
            <motion.span
              className="container-fluid faq-heading w-100"
              data-aos="fade-right"
              data-aos-delay="300"
              data-aos-duration="400"
            >
              {props.question}
            </motion.span>
            <button
              className="inline-block faq-action"
              onClick={() => setOpen(!open)}
            >
              <FontAwesomeIcon icon={open ? faMinus : faPlus} />
            </button>
          </div>
          <motion.span
            className={open ? "faq-answer p-md-4 w-100 p-1" : "visibility-hidden"}
            animate={open ? { height: "auto" } : { height: 0 }}
            initial={open ? { height: 0 } : { height:  0 }}
          >
            <span className={open ? "" : "d-none"}> {props.answer}</span>
          </motion.span>
        </div>
      </span>
    </>
  );
};

// To align the content of frequently asked question to left we have
export const FrequentlyAskedQuestionRight = (props) => {
    var valueOpen = Boolean(props.defaultValue);
  const [rightOpen, setRightOpen] = useState(valueOpen);
    return (
      <>
        <span className="p-2 m-0 w-100 faq-slicer text-left text-white d-md-flex justify-content-md-start">
          <div className="faq-container d-flex flex-column">
            <div className="d-flex justify-content-end">
              <span
                className="container-fluid faq-heading w-100"
                data-aos="fade-left"
                data-aos-delay="800"
                data-aos-duration="1000"
              >
                {props.question}
              </span>
              <button
                className="inline-block faq-action"
                onClick={() => setRightOpen(!rightOpen)}
              >
                <FontAwesomeIcon icon={rightOpen ? faMinus : faPlus} />
              </button>
            </div>
            <motion.span
              animate={rightOpen ? { height: "auto" } : { height: 0 }}
              initial={rightOpen ? { height: 0 } : { height: 0 }}
              className={rightOpen ? "faq-answer p-md-4 p-2" : "visibility-hidden"}
            >
             <span className={rightOpen? "": "d-none"} >{props.answer}</span>
            </motion.span>
          </div>
        </span>
      </>
    );
};