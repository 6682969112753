import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Portfolio from "./pages/portfolio";
import Home from "./pages/home";
import PrivacyPolicy from "./pages/privacy-policy";
import { Terms } from "./pages/terms";
import { Blog } from "./pages/blog";
import ScrollToTop from "./components/scroll-to-top";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <ScrollToTop />
            <Home />
          </Route>
          <Route exact path="/privacy-policy">
            <ScrollToTop />
            <PrivacyPolicy />
          </Route>
          <Route exact path="/portfolio">
            <ScrollToTop />
            <Portfolio />
          </Route>
          <Route exact path="/terms">
            <ScrollToTop />
            <Terms />
          </Route>
          <Route exact path="/blog">
            <ScrollToTop />
            <Blog />
          </Route>
          {/* <Router>
            <Route path="/pdf" render={() => <PdfViewer file="/assets/user/sml.pdf" />} />
          </Router> */}
          {/* <Route>
            <Redirect to="/"></Redirect>
          </Route> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;

// <Router>
//   <Route path="/">
//     <Home />
//   </Route>
// </Router>;
