import React from "react";
// import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { hydrate, render } from "react-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../src/styles/icomoon/icomoon.css";
AOS.init();


const rootElement = document.getElementById("root");


if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
reportWebVitals(console.log);
