import React from "react";
import "../styles/happy-list.sass";

// primary-orange
// primary-blue-bg
// d-flex
// happy-list

function HappyList() {
  return (
    <>
      <div className="happy-list-section container-fluid p-0 m-0">
        <h3
          className="happy-list-title font-weight-bold py-4 primary-orange font-600"
          data-aos="zoom-in"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          Our Happy list
        </h3>
        <div className="container-fluid  primary-blue-bg">
          <div className="happy-list row text-white d-flex justify-content-center align-content-center">
            <div className="w-25 d-flex flex-column font-600">
              <h1 data-aos="fade-right" data-aos-duration="500">
                25+
              </h1>
              <p data-aos="fade-right" data-aos-duration="500">
                Team Members
              </p>
            </div>
            <div className="boxed"></div>
            <div className=" w-25 d-flex flex-column font-600">
              <h1 data-aos="fade-up" data-aos-duration="500">
                100+
              </h1>
              <p data-aos="fade-up" data-aos-duration="500">
                Enquiries Generated
              </p>
            </div>
            <div className="boxed"></div>
            <div className="w-25 d-flex flex-column font-600">
              <h1 data-aos="fade-left" data-aos-duration="500">
                30+
              </h1>
              <p data-aos="fade-left" data-aos-duration="500">
                Clients Served
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HappyList;
