import React from "react";
import AppBar from "../components/app-bar";

export const Blog = () => (
  <>
    <AppBar />
    <div className="container">

    <h1 className="primary-blue">Swift Media Labs Blogging Page</h1>
    <p className="primary-red">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro quis a
      aliquam natus consectetur tempora nemo commodi ullam alias libero, unde
      incidunt eaque excepturi obcaecati ducimus architecto quasi sint! Rerum
      error officiis repudiandae adipisci sed, sapiente totam ab distinctio modi
      deserunt tempore dolorum explicabo odio, quis debitis aspernatur dolorem
      dolore impedit eaque quae nihil! Tempore.
    </p>
    </div>
  </>
);
