import React from 'react';
import { FrequentlyAskedQuestion, FrequentlyAskedQuestionRight } from './faq-container';
// faq-container 
// faq-heading
// faq-action

function FAQ() {
    return (
      <>
        <div className="container-fluid py-4" id="faq">
          <div className="d-flex flex-column justify-content-center">
            <h4
              className="primary-orange font-600 faq-title"
              data-aos="zoom-in"
              data-aos-duration="500"
            >
              Frequently Asked Questions
            </h4>
            <p
              className="primary-red py-md-4"
              data-aos="zoom-out"
              data-aos-duration="500"
            >
              Our Plans have everything you need to take your project to the
              next level
            </p>
          </div>
          <div className="container-fluid row">
            <div className="col-12 col-md-6 text-right">
              {/* Frequently Asked Questions */}
              <FrequentlyAskedQuestion
                question="Would my business benefit from Digital Marketing?"
                answer="
Customers today are more knowledgeable and hooked to online platforms. Customers today also gain most of their information through smart devices. Therefore, any business will definitely benefit from digital marketing as use of right digital marketing strategy will not only help to reach your customers effectively it will help your business to be connected with them all the time and also listen to their needs and wants."
              />
              <FrequentlyAskedQuestion
                question="Why is SEO important?"
                answer="The majority of web traffic is generated by search and those that come by way of search have a greater degree of commercial intent than most other channels."
              />
              <FrequentlyAskedQuestion
                question="Which Social Media Channels should I use?"
                answer="Consider first the top social channels in terms of active users: Facebook, Instagram, Twitter, LinkedIn, and Pinterest. Determine where your audience spends their time. Research the demographics and behavioral patterns of the users and consider the type of content you’ll share."
              />
            </div>
            <div className="col-12 col-md-6">
              <FrequentlyAskedQuestionRight
              defaultValue={false}
                question="Why do I need a Website?"
                answer="Consumers are learning to be pretty savvy when they research online. In fact, 75% will judge your credibility based on your website design. That’s why it’s so important to have a modern, up-to-date website with a custom domain and other markers of trustworthiness. A website also shows that you are open for business during a time when there is a lot of uncertainty.
Many small businesses who don’t have a website still rely on Yellow Page ads or traditional advertising to get their name out there. But is purchasing an ad in ever-shrinking and increasingly expensive directory the best use of funds, especially if you’re trying to cut business costs? Websites cost much less than they have in the past and give you a wider reach than regular advertising. "
              />
              <FrequentlyAskedQuestionRight
              defaultValue={true}
                question="How to handle negative comments on social media?"
                answer="First of all, you can choose to address your customers’ concerns in an attempt to regain their trust. Bear in mind that in some cases there can be no retention if you’re not doing so. Even satisfied customers will start questioning your customer service standards if they notice such approaches."
              />
            </div>
          </div>
        </div>
      </>
    );
}
export default FAQ