
import SwiperCore, { EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
// import 'swiper/components//autoplay.scss';
// import 'swiper/components/navigation/navigation.scss';
import './../styles/clients.sass'
// import images
import Client1 from "../assets/clients/amar.webp"
import Client2 from "../assets/clients/hina.webp"
import Client3 from "../assets/clients/shalya.webp"
import Client4 from "../assets/clients/nishthas.webp"
import Client5 from "../assets/clients/adventure-things.webp"
import Client6 from "../assets/clients/perfect-n-portrait.png"
import Client7 from "../assets/clients/ripro.jpg"
import Client8 from "../assets/clients/siyavar-energy.png";

const Clients = () => {
    SwiperCore.use([EffectFade]);
    SwiperCore.use([Autoplay]);
    return (
      <>
        <h5
          className="primary-orange font-600 font-weight-bold clients-title pb-4"
          data-aos="zoom-in-up"
          data-aos-duration="500"
        >
          Clients we have worked for
        </h5>
        <div className="container-fluid">
          <div className="client-slideshow w-md-100 h-md-25 row">
            <div
              className="d-md-flex 
                    container flex-row justify-content-center align-content-center h-25 w-75"
            >
              <Swiper
                spaceBetween={20}
                slidesPerView={4}
                navigation
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                loop
                autoplay={true}
              >
                <SwiperSlide>
                  <span className="img-container-1">
                    <img src={Client1} alt="client amar atheletics" />
                  </span>
                </SwiperSlide>
                <SwiperSlide>
                  <span className="img-container-2">
                    <img src={Client2} alt="client hina closet" />
                  </span>
                </SwiperSlide>
                <SwiperSlide>
                  <span className="img-container-3">
                    <img src={Client3} alt="client shalya natural" />
                  </span>
                </SwiperSlide>
                <SwiperSlide>
                  <span className="img-container-4">
                    <img src={Client4} alt="client nishthas" />
                  </span>
                </SwiperSlide>
                <SwiperSlide>
                  <span className="img-container-5">
                    <img src={Client5} alt="client adventure" />
                  </span>
                </SwiperSlide>
                <SwiperSlide>
                  <span className="img-container-3">
                    <img src={Client6} alt="perfect n portrait" />
                  </span>
                </SwiperSlide>
                <SwiperSlide>
                  <span className="img-container-5">
                    <img src={Client7} alt="ripro constructions" />
                  </span>
                </SwiperSlide>
                <SwiperSlide>
                  <span className="img-container-3">
                    <img src={Client8} alt="siyavar energy" />
                  </span>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </>
    );
};
export default Clients