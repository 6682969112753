import firebase from "firebase/app";
import "firebase/firestore";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAUQTDoIF2C8rNs7oQ0VTKoXnteyd3iJ9k",
  authDomain: "sml-admin-panel.firebaseapp.com",
  projectId: "sml-admin-panel",
  storageBucket: "sml-admin-panel.appspot.com",
  messagingSenderId: "163562799494",
  appId: "1:163562799494:web:2b2ef6ded0113c534eac07",
  measurementId: "G-W6WVYFPSRZ",
});

var db = firebaseApp.firestore();
export { db };
