import React, { useState } from "react";
import "../styles/contact.sass";
import EnvelopeImage from "../assets/icons/envelope.webp";
// import axios from "axios";
import { db } from "../firebase";

function Contact() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [data, setData] = useState({
        name: name,
        email: email,
    });

    function sendEmailData(e) {
        e.preventDefault();
        setName(email);
        setData({
            name: name,
            email: email,
        });
        // axios.post('http://localhost:5000/api/sendMail', data);
        db.collection("emails")
            .add({
                name: name,
                email: email,
            })
            .then(() => {
                alert("Thank you for contacting Swift Media Labs");
            })
            .catch((error) => {
                alert(error.message);
            });
        setEmail("");
        setName("");
        console.log("email is " + email + "name is " + name);
        console.log(data.email);
    }
    return (
        <>
            <div
                className="contact-section py-4 container-fluid primary-blue-bg"
                id="contact"
            >
                <div
                    className="contact-envelope d-none d-md-block"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                >
                    <img src={EnvelopeImage} alt="Envelope" />
                </div>
                <div className="contact-mobile-envelope d-block d-md-none">
                    <img src={EnvelopeImage} alt="Envelope" />
                </div>
                <h4
                    className="text-white font-600 p-2"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                >
                    HAVE A PROJECT FOR US! THEN WHAT ARE YOU WAITING FOR
                </h4>
                <p
                    className="text-white"
                    data-aos="zoom-out"
                    data-aos-duration="500"
                >
                    Where there is a problem, the Digital solution lies with
                    Swift Media Labs!
                    <br />
                    Enter your email id to get your problems solved.
                </p>
                <div className="container-fluid d-flex justify-content-center flex-row">
                    <span className="contact-email primary-orange-bg d-flex ">
                        <form
                            onSubmit={sendEmailData}
                            data-aos="flip-left"
                            data-aos-duration="500"
                            className="d-flex flex-row"
                        >
                            <input
                                type="email"
                                placeholder="Enter your email"
                                className="text-white"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button type="submit">SEND</button>
                        </form>
                    </span>
                </div>
            </div>
        </>
    );
}
export default Contact;
