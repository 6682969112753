import React from "react";
import { motion } from "framer-motion";


export const ServicesButton = (props) => (
  <div
    className="w-50 d-flex d-md-flex justify-content-center"
    data-aos="fade-right"
    data-aos-duration="500"
  >
    <div
      className="services-action-button d-flex justify-content-center align-items-center"
      onClick={props.clickAction}
    >
      {props.display}
    </div>
  </div>
);

export const ServicesSlideShowComponent = (props) => {
  const arrayItems = props.listItems;
  const arrayItems1 = props.listItems1;
  const arrayItems2 = props.listItems2;
  const arrayList =
    arrayItems.length > 1
      ? arrayItems.map((list, index) => (
          <motion.div
            animate={props.animateElement ? { x: 0 } : { x: 1200 }}
            initial={props.animateElement ? { x: 1200 } : { x: 0 }}
            transition={{ delay: 1 + index * 0.1, duration: 0.3 }}
            className="list-items"
          >
            <input type="checkbox" checked className="custom-checkbox" /> {list}{" "}
          </motion.div>
        ))
      : null;
  const arrayList1 =
    arrayItems1.length > 1
      ? arrayItems1.map((list1,index) => (
          <motion.div
            animate={props.animateElement ? { x: 0 } : { x: 1200 }}
            initial={props.animateElement ? { x: 1200 } : { x: 0 }}
            transition={{ delay: 1.3 + index * 0.1, duration: 0.3}}
            className="list-items"
          >
            <input type="checkbox" checked className="custom-checkbox" />{" "}
            {list1}{" "}
          </motion.div>
        ))
      : null;
  const arrayList2 =
    arrayItems2.length > 1
      ? arrayItems2.map((list2,index) => (
          <motion.div
            animate={props.animateElement ? { x: 0 } : { x: 1200 }}
            initial={props.animateElement ? { x: 1200 } : { x: 0 }}
            transition={{ delay: 1.5 + index * 0.1 , duration: 0.3}}
            className="list-items"
          >
            <input type="checkbox" checked className="custom-checkbox" />{" "}
            {list2}{" "}
          </motion.div>
        ))
      : null;

  return (
    <div
      className="text-left bg-white p-4"
      data-aos="fade-left"
      data-aos-duration="500"
    >
      <motion.h1
        animate={props.animateElement ? { x: 0 } : { x: 1200 }}
        initial={props.animateElement ? { x: 1200 } : { x: 0 }}
        transition={{ delay: 0.2, duration: 0.3 }}
        className="primary-red"
        data-aos="fade-left"
        data-aos-duration="500"
      >
        {props.title}
      </motion.h1>
      <motion.p
        animate={props.animateElement ? { x: 0 } : { x: 1200 }}
        initial={props.animateElement ? { x: 1200 } : { x: 0 }}
        transition={{ delay: 0.4, duration: 0.3 }} className="primary-blue" data-aos="fade-left" data-aos-duration="500">
        {props.text}{" "}
      </motion.p>
      <p>
        <div
          className="primary-blue text-left"
          data-aos="fade-left"
          data-aos-duration="500"
        >
          {arrayList}
        </div>
        <div className="primary-blue text-left">
          <div className="row">
            <div className="col-6" data-aos="fade-left" data-aos-duration="500">
              {arrayList1}
            </div>
            <div className="col-6" data-aos="fade-left" data-aos-duration="500">
              {arrayList2}
            </div>
          </div>
        </div>
      </p>
    </div>
  );
};

export const ServicesSlideShowMobile = (props) => {
  const arrayItems = props.listItems;
  const arrayItems1 = props.listItems1;
  const arrayItems2 = props.listItems2;
  const arrayList =
    arrayItems.length > 1
      ? arrayItems.map((list, index) => (
          <motion.div
            animate={props.animateElement ? { x: 0 } : { x: 1200 }}
            initial={props.animateElement ? { x: 1200 } : { x: 0 }}
            transition={{ delay: 1 + index * 0.1, duration: 0.3 }}
            className="list-items"
            data-aos="fade-right"
            data-aos-duration="500"
          >
            <input type="checkbox" checked className="custom-checkbox" /> {list}{" "}
          </motion.div>
        ))
      : null;
      
  const arrayList1 =
    arrayItems1.length > 1
      ? arrayItems1.map((list1,index) => (
          <motion.div
            animate={props.animateElement ? { x: 0 } : { x: 1200 }}
            initial={props.animateElement ? { x: 1200 } : { x: 0 }}
            transition={{ delay: 1.2 + index * 0.1, duration: 0.34}}
            className="list-items"
            data-aos="fade-right"
            data-aos-duration="500"
          >
            <input type="checkbox" checked className="custom-checkbox" />{" "}
            {list1}{" "}
          </motion.div>
        ))
      : null;
  const arrayList2 =
    arrayItems2.length > 1
      ? arrayItems2.map((list2, index) => (
          <motion.div
            className="list-items"
            data-aos="fade-right"
            data-aos-duration="500"
            animate={props.animateElement ? { x: 0 } : { x: 1200 }}
            initial={props.animateElement ? { x: 1200 } : { x: 0 }}
            transition={{ delay: 1.3 + index * 0.1, duration: 0.3 }}
          >
            <input type="checkbox" checked className="custom-checkbox" />{" "}
            {list2}{" "}
          </motion.div>
        ))
      : null;

  return (
    <div
      //   animate={props.animateElement ? { y: 0 } : { y: 100 }}
      //   initial={props.animateElement ? { y: 100 } : { y: 0 }}

      className="text-left bg-white p-4 services-inner-content-mobile"
      data-aos="fade-right"
      data-aos-duration="500"
    >
      <motion.h1
        className="primary-red"
        animate={props.animateElement ? { x: 0 } : { x: 1200 }}
        initial={props.animateElement ? { x: 1200 } : { x: 0 }}
        transition={{ delay: 0.3, duration: 0.3 }}
        data-aos="fade-left"
        data-aos-duration="500"
      >
        {props.title}
      </motion.h1>
      <motion.p
        animate={props.animateElement ? { x: 0 } : { x: 1200 }}
        initial={props.animateElement ? { x: 1200 } : { x: 0 }}
        transition={{ delay: 0.7, duration: 0.3 }}
        className="primary-blue"
        data-aos="fade-left"
        data-aos-duration="500"
      >
        {props.text}{" "}
      </motion.p>
      <p>
        <div
          className="primary-blue text-left"
          data-aos="fade-right"
          data-aos-duration="500"
        >
          {arrayList}
        </div>
        <div
          className="primary-blue text-left"
          data-aos="fade-right"
          data-aos-duration="500"
        >
          <div className="row">
            <div
              className="col-6"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              {arrayList1}
            </div>
            <div
              className="col-6"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              {arrayList2}
            </div>
          </div>
        </div>
      </p>
    </div>
  );
};
