import React from 'react';
import AppBar from "./../components/app-bar";
import Header from "./../components/header";
import AboutUs from "./../components/about-us";
import OurServices from "./../components/our-services";
import CoreTechnologies from "./../components/core-technologies";
import Workflow from "./../components/workflow";
import Clients from "./../components/clients";
import HappyList from "./../components/happy-list";
import FreeWebsiteAudit from "./../components/free-website-audit";
import FrequentlyAskedQuestions from "./../components/frequently-asked-questions";
import Contact from "./../components/contact";
import SiteInfo from "./../components/site-info";

function Home() {
  return (
    <div className="App row p-0 m-0">
      <div className="container-fluid col-md-12 p-0 m-0">
        <AppBar />
        <Header />
        <AboutUs />
        <OurServices />
        <CoreTechnologies />
        <Workflow />
        <Clients />
        <HappyList />
        <FreeWebsiteAudit />
        <FrequentlyAskedQuestions />
        <Contact />
        <SiteInfo />
      </div>
    </div>
  );
}

export default Home;
