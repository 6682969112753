import React, { useState } from "react";
import "../styles/free-website-audit.sass";
import AuditBottomImage from "./../assets/contact-section/contact-section.svg";
import AuditBottomMobile from "./../assets/contact-section/contact-section-mobile.svg";
import { db } from "../firebase";

function FreeWebsiteAudit() {
  const [audit, setAudit] = useState("");

  function submitAudit(e) {
    e.preventDefault();
    db.collection("audits")
      .add({
        site: audit,
      })
      .then(() => {
        alert(
          "Thank you for sending your website for audit to Swift Media Labs"
        );
      })
      .catch((error) => {
        alert(error.message);
      });
  }
  return (
    <>
      <div>
        <div className="free-audit-section  position-absolute container-fluid p-0 text-white d-flex flex-column justify-content-start justify-content-md-center align-items-center">
          <div className="container-fluid d-flex flex-column justify-content-center align-items-center free-audit-form ">
            <div>
              <h1
                className="free-website"
                data-aos="zoom-in"
                data-aos-delay="800"
                data-aos-duration="800"
              >
                FREE
              </h1>
              <h3
                className="website-audit"
                data-aos="zoom-in"
                data-aos-delay="800"
                data-aos-duration="800"
              >
                WEBSITE AUDIT
              </h3>
            </div>
            <div className="container-fluid w-md-50">
              <p
                data-aos="zoom-out"
                data-aos-delay="800"
                data-aos-duration="800"
              >
                So, You have a website? That's good! <br/>Let's help you make it better,
             by just entering your website below <br/>
                
              </p>
            </div>
            <div className="container-fluid">
              <span
                className="container-fluid span"
                data-aos="flip-right"
                data-aos-delay="800"
                data-aos-duration="800"
              >
                <input
                  class="input-email"
                  type="url"
                  placeholder="https://yourfirm.com"
                  data-aos="flip-right"
                  data-aos-delay="800"
                  data-aos-duration="800"
                  onChange={(e) => setAudit(e.target.value)}
                  value={audit}
                />
              </span>
              <span className="container-fluid">
                <button
                  class="try-btn"
                  data-aos="flip-left"
                  data-aos-delay="800"
                  data-aos-duration="800"
                  onClick={submitAudit}
                >
                  Try for free
                </button>
              </span>
            </div>
          </div>
        </div>
        <img
          src={AuditBottomImage}
          alt="Audit Bottom"
          className="d-none d-md-block audit-bottom-img"
        />
        <img
          src={AuditBottomMobile}
          alt="Audit Bottom"
          className="d-block d-md-none audit-bottom-img"
        />
      </div>
    </>
  );
}
export default FreeWebsiteAudit;
