import React from "react";
import AppBar from "./../components/app-bar";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="App row p-0 m-0">
        <AppBar />
        <div className="container p-4 primary-blue text-left">
            <h1>Welcome to our Portfolio Page</h1>
            <p className="primary-red">Thank you for visiting the Portfolio Page of Swift Media Labs, we are working on building something great.. as you know Rome wasn't build in a day. Please check back soon😇</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
